.not-on-top {
  height: 100vh;
  overflow: hidden;
}

.ListPage {
  position: relative;
  margin: 0 auto 1rem;
  max-width: 70rem;

  .Hero {
    background-size: cover;
    transition: opacity 0.5s ease-in;

    .isLive & {
      opacity: 1;
    }

  }

  .UserNameContainer {
    padding-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5.7rem;
    color: white;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.75);
    min-width: 20rem;
    text-align: center;
    text-overflow: ellipsis;
    opacity: 1;
    transition: opacity 0.3s ease-in;

    .isLive & {
      color: white
    }
  }

  .FilterContainer {
    .nav-link,
    .nav-link.active,
    .nav-link:hover {
      border-radius: 0;
      background: none;
      color: #2196F3;
    }
  
    .nav-link:hover {
      border-bottom: 3px solid #2196F366;
    }
    
    .nav-link.active,
    .nav-link.active:hover {
      border-bottom: 3px solid #2196F3;
    }
  }
}

.LivePage.cover {
  .Hero {
    height: 100vh;
    width: 100vw;
    background-position: center;
    
    @media (min-width: 70rem) {
      margin-left: calc(-50vw + 35rem);
    }
  }

  .UserNameContainer {
    top: auto;
    left: 7rem;
    bottom: calc(1rem + 2.5rem);
    transform: translate(0,50%);
    text-align: left;
    margin: 0;
    font-size: 0.7em;

    @media (min-width: 70rem) {
      left: calc(-50vw + 35rem + 7rem);
    }
  }
}

.ListPage.container-fluid {
  margin-left: 0;
}

.thumbnail {
  border-radius: 4px;
}

.toolbar {
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  background: #f7f7f7;
  right: 0;
  text-align: center;
  padding: 0.5rem;

  .action {
    margin: 0 1.5rem;
  }

  .action.import {
    position: absolute;
    right: 1rem;
  }
}

.ListPage-User,
.ListPage-Invitation,
.ListPage-Page {
  max-width: 70rem;
  min-height: 101vh;
  margin-top: 4rem;
  transition: margin-top 0.2s;

  .List-Container {
    padding-bottom: 2.5em;
  }

  .isLive & {
    margin-top: 1.5rem;
  }

  .live-list-item {
    flex-direction: column;
    text-align: center;

    &:hover {
      background-color: transparent;
    }

    h2 {
      font-size: 1.75em;
    }

    h3 {
      font-size: 1em;
    }
  }
}

.AvatarWrapper {
  width: 15rem;
  height: 15rem;
  margin: 0 auto;
  
  &.hidden {
    visibility: hidden;
  }
}

.Avatar {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: center top;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
  border: 2px solid white;
  background-color: rgba(0,0,0,0.05);
}

.MainMenu-sticky {
  display: none!important;
  position: fixed;
}

.FilterContainer {
  position: absolute;

  .InfernoFormlib-FormRows {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  #search__Row {
    width: 0;
  
    .form-control-label {
      display: none;
    }
  
    #search__Field {
      border-radius: 2em;
    }
  }
  
  .sticky-row {
    background: none;
    bottom: auto;
    z-index: 9;
    border-top: none;

    .form-nav,
    form {
      background: rgba(255,255,255,0.8);
    }
  }

  .form-nav,
  form {
    padding: 8px 0 0;
    background: white;
  }

  .form-nav {
    justify-content: center;
  }

  .form-group {
    margin-bottom: 8px;
  }
}

.action-wrapper {
  .placeholder {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -.75rem;
    left: 50%;
    transform: translate(-50%, -100%);
  
    background: hsl(0, 0%, 93%);
    border-radius: 8px;
    text-align: center;
    width: calc(100vw - 4rem);
    padding: 2rem 2rem 1rem;
    max-width: 30rem;
    align-content: center;
    justify-content: center;
    min-height: 10rem;
    color: var(--gray);
  
    &:after {
      content: " ";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        width: 0; 
        height: 0; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid hsl(0, 0%, 93%);
    }
  }
}