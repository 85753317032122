$theme-colors: (
  "success": #45EE7E,
  "primary": #49C0E7,
  "info": #FFA74A,
  "danger": #FF664A
);

:root {
  --primary-60: #49C0E799;
  --primary-40: #49C0E766;
  --primary-20: #49C0E733;
}

// $btn-link-disabled-color: rgba(255,255,255,0.5);

/* TYPOGRAPHY */

$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 400;

$line-height-base: 1.7;

$headings-font-family: 'Open Sans', Roboto, sans-serif;
$headings-font-weight: 300;
