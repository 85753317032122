.MediaWidget {
  .container-fluid {
    flex-direction: column;
    display: flex;
    height: 100%;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .MediaSelector-ThumbnailContainer {
    overflow-y: scroll;
    position: absolute;
    bottom: 0;
    top: 0;
    height: 100%;
  }

  .MediaSelector-ThumbnailDetails {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    &.no-selection {
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 1.3em;
      }
      .btn {
        align-self: center;
        margin-bottom: 1rem;
      }
      .info {
        text-align: center;
        font-size: 0.75em;
      }
    }
  }

  .MediaSelector-List {
    position: relative;
    height: 100%;
  }

  .MediaSelector-Filter {
    position: absolute;
    top: -3.7em;
    right: 1em;
  }

  #search__Field {
    border-radius: calc((1.7em + 0.75rem) / 2);
    padding-left: 1em;
  }
}


.MediaSelector-Progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  border-radius: 0!important;
}

.MediaSelector-Hint {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  background: #f0f0f0;
  border-radius: 4px;
  color: #bbb;
  padding: 1rem;
}

.DragNDroppable {
  
  .text {
    display: none;
  }
  
  &.DragNDroppable--drag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    
    .text {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 400;
      color: white;
      font-size: 1.85em;
    }
  }
}

.Unsplash-MediaSelectItem {
  .media-body {
    padding-top: 0.3rem;

    img {
      border-radius: 50%;
      width: calc(1rem + 4px);
      height: calc(1rem + 4px);
      border: 1px solid white;
      box-shadow: rgba(0,0,0,0.2) 1px 1px 3px;
      margin-right: 0.3rem;
    }

    a {
      color: white;
    }
  }
}

.MediaSelector-DownloadOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
}