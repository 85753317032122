.Gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .image,
  img {
    cursor: pointer;
    object-fit: cover;
    max-height: 50vh;
    position: relative;

    i {
      content: attr(data-show-more);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Times New Roman', Times, serif;
      font-weight: 400;
      font-size: 4em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
  }
  .image {
    img {
      height: 100%;
    }
    i {
      pointer-events: none;
    }
  }

  &.cover {
    align-items: unset;
    img {
      object-fit: cover;
    }
  }
  
  &.two .image:nth-child(1) {
    width: calc(50% - 1px);
    margin-right: 1px;
  }
  &.two .image:nth-child(2) {
    width: calc(50% - 1px);
    margin-left: 1px;
  }
  
  &.one_two .image:nth-child(1) {
    margin-bottom: 2px;
  }
  &.one_two .image:nth-child(2) {
    width: calc(50% - 1px);
    margin-right: 1px;
    max-height: 25vh;
  }
  &.one_two .image:nth-child(3) {
    width: calc(50% - 1px);
    margin-left: 1px;
    max-height: 25vh;
  }
  
  &.one_three .image:nth-child(2) {
    width: calc(33.33% - 2px);
    margin-right: 2px;
    margin-top: 2px;
    max-height: 25vh;
  }
  &.one_three .image:nth-child(3) {
    width: calc(33.33% - 2px);
    margin-left: 1px;
    margin-right: 2px;
    margin-top: 2px;
    max-height: 25vh;
  }
  &.one_three .image:nth-child(4) {
    width: calc(33.33% - 2px);
    margin-left: 1px;
    margin-top: 2px;
    max-height: 25vh;
  }

  &.one_two_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 55% 45%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    margin-left: -4rem;
    width: calc(100% + 8rem);
    max-width: 100vw;

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;
      
      img {
        max-height: 100%;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }

  &.one_four_horizontal {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 40% 30% 30%;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    margin-left: -4rem;
    width: calc(100% + 8rem);
    max-width: 100vw;

    .image:nth-child(1) {
      grid-row: 1 / -1;
      max-height: 100%;
      
      img {
        max-height: 100%;
      }
    }
    .image:nth-child(2) {}
    .image:nth-child(3) {}
  }
}

.EditModal,
.Lightbox {
  overflow: hidden;

  h2 {
    margin-top: 0.25em;
    align-self: center;
    text-align: center;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h3 {
    width: 100%;
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: center;
  }

  .modal-header {
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0rem;
    height: 3rem;
    z-index: 99;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
  }

  .modal-title {
    align-self: center;
    margin-right: auto;
  }

  .modal-content .modal-body {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 3rem 0;
    overflow-x: hidden;

    & > h2, & > h3 {
      text-align: center;
    }
  }

  .media {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-footer {
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    transform: translateY(100%);
    height: 3rem;
    max-height: calc(100vh - 3rem);
    overflow-y: scroll;
    left: 0;
    border-radius: 0;
    transition: all 0.3s ease-in;
    background: rgba(255, 255, 255, 0.8);

    &.expand {
      height: 100%;
      transform: translateY(0);
    }

    p {
      margin-right: auto;
    }
  }
  .lightbox-image {
    max-width: calc(100% + 30px);
    margin-bottom: 1rem;
    max-height: calc(100vh - 6rem);
    width: auto;
    margin-left: 50%;
    transform: translateX(-50%);

    .image:last-child & {
      margin-bottom: 0;
    }
  }

  .thumbnail {
    max-height: 240px;
    max-width: 240px;
  }

  .CloseButton.btn {
    align-self: center;
    margin-left: 0;
    margin-right: auto;
    flex: 0 0 auto;
  }
}

.EditModal {
  .modal-content .modal-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.CardEditButton {
  position: absolute;
  top: 0.5rem;
  right: 0;
}
