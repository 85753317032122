.modal-dialog.CardPanel {
  width: calc(100vw - 2rem)!important;
  max-width: 50rem;
  background-color: white;
  overflow-wrap: scroll;
  margin-top: 2.1rem;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  box-shadow: 4px 4px 8px rgba(0,0,0,0.2);

  .modal-header {
    border-bottom: none;
    padding: 1rem;
  }

  .modal-title {
    font-size: 1.4em;
    font-weight: 400;
    margin: 0;
    line-height: 1em;
    color: #ddd ;
  }

  .modal-body {
    columns: 2;
    column-gap: 4rem;
    column-fill: auto;
    padding: 0 1.5rem;

    @media (max-width: 576px) {
      columns: 1;
    }
  }

  .CardPanel-Group {
    display: inline-block;
    text-align: left;
    margin-bottom: 0.5rem;

    @media (max-width: 576px) {
      & > p {
        display: none;
      }
      
      & > h3 {
        display: none;
      }
    }

    h3 {
      font-size: 1em;
      margin-bottom: 1em;
      text-align: left;
      color: var(--primary);
      font-weight: 400;
    }

    .media {
      cursor: pointer;
      margin: -0.25rem -0.25rem 1.25rem;
      padding: 0.25rem;
      border-radius: 4px;
      min-height: 3.2rem;

      p {
        margin-bottom: 0;
      }

      &:hover {
        background-color: var(--primary);
        color: white;
      }
    }

    .arrow {
      width: 1.44em;
      height: 1.44em;
      margin: 0 0 0 0.5em;
      vertical-align: -0.35em;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      margin-top: 0.3rem;

      
      color: var(--primary);
      fill: currentColor;
      stroke: currentColor;
      .inverse {
        fill: white;
      }
      .inverse-stroke {
        stroke: white;
      }
    } .media:hover {
      svg {
        color: white;
        .inverse {
          fill: var(--primary);
        }
        .inverse-stroke {
          stroke: var(--primary);
        }
      }
    }

    h4 {
      font-size: 1em;
      margin-bottom: 0.25em;
    }

    p {
      font-size: 0.7em;
      line-height: 1.25em;
    }





  }
  
  .modal-footer {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #eee;
    
    @media (max-width: 576px) {
      display: none;
    }
    
    p {
      display: inline-block;
      font-size: 0.7em;
      line-height: 1.25em;
      margin: 0 0 0.5em;
    }
  }
}