.Live-TimedPublishing-Banner {
  padding: 0.5rem;
  background-color: #f3dcce;
  position: relative;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &::before {
    content: '';
    width: 1.85em;
    height: 1.85em;
    transform: rotate(45deg) translate(-30%, 50%);
    position: absolute;
    bottom: -0.45em;
    left: 50%;
    border-bottom: 0.75em solid #f3dcce;
    border-right: .75em solid #f3dcce;
  }

  h2 {
    font-size: 1.75em;
    margin-left: 1rem;
    margin-bottom: 0.2em;
    text-align: center;
    color: #c58660;
    font-weight: bold;
  }
  h3 {
    font-size: 1em;
    margin-bottom: 0;
    text-align: center;
    z-index: 2;
  }
  .Icon-Clock {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 2em;
    height: 2em;
    margin-bottom: 0;
  }
}