/* Bootstrap overrides */
.invalid-feedback {
  display: block!important;
}
 
/**
 * Animate error messages
 */
 .InfernoFormlib-ErrorMsg--Animation {
  &-leave {
    opacity: 1;
  }
  
  &-leave-active {
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }
  
  &-leave-end {
    height: 0;
    opacity: 0;
  }
  
  &-enter {
    height: 0;
    opacity: 0;
  }
  
  &-enter-active {
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }
  
  &-enter-end {
    opacity: 1;
  }
}

/**
 * Animate rows and list rows
 */
.InfernoFormlib-ListFieldRow--Animation,
.InfernoFormlib-Row--Animation {
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }
  
  &-leave-end {
    height: 0;
    opacity: 0;
  }
  
  &-enter {
    height: 0;
    opacity: 0;
  }
  
  &-enter-active {
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }
  
  &-enter-end {
    opacity: 1;
  }
}


/* DateField Widget */
a.InfernoFormlib-DateField-unset {
  opacity: 1;
  margin-left: 0.5rem;
  width: 1em;
  transition: 0.3s ease-in;
  color: red;
  cursor: pointer;
  align-self: center;
  font-size: 0.75em;

  .InfernoFormlib-DateTimeGroup--noValue & {
    opacity: 0;
    margin-left: 0;
    width: 0;
  }
}
.InfernoFormlib-DateFieldCalendar {
  .DateFieldHeaderItem {
    text-align: center;
    color: #aaa;
  }
  
  .DateFieldItem {
    text-align: center;
    color: #666;
  }
  
  .DateFieldItem.text-muted a {
    color: #bbb !important;
  }
  
  .DateFieldItem--today {
    background-color: #eee;
    border-radius: 0.2em;    
  }
  
  .DateFieldItem:hover,
  .DateFieldItem--selected {
    color: white !important;
    background-color: #007bff;
    border-radius: 0.2em;
  } 
  
  .DateFieldBody {
    min-width: 20em;
    min-height: 14em;
  }
  
  .DateFieldFooter {
    padding: 0.5rem;
    text-align: center;
    background-color: #f7f7f7;
    border-top: 1px solid #ebebeb;
  }
  
  .nav-item {
    flex: 0 1;
  }
  
  .nav-link:hover {
    border-radius: 0.2em;
    color: white;
    background-color: #2196F3;
  }
  
  .DateFieldMonth {
    flex: 1 0;
    text-align: center;
  }

  .DateField-YearSelect {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 1rem;
  }

  .DateField-YearSelect-day {
    list-style: none;
    padding: 0.5rem;
    color: var(--primary);
    cursor: pointer;
  }

  .DateField-YearSelect-minus,
  .DateField-YearSelect-plus {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0.5rem;

    i {
      align-self: center;
      font-weight: bold;
      font-style: normal;
      color: var(--primary);
      cursor: pointer;
    }
  }
  .DateField-YearSelect-minus { left: 0 }
  .DateField-YearSelect-plus { right: 0 }
}  


/**
*  DateTimeWidget
*/
.input-group.InfernoFormlib-DateTimeGroup {
  display: block;
  
  & > .InfernoFormlib-DateTime_date {
    display: inline-block;
    width: 7.5em;
    transition: all 0.15s linear;
  }
  &--noValue > .InfernoFormlib-DateTime_date {
    width: 13.5em!important;
    border-radius: 0.25rem!important;
  }
  
  & > .InfernoFormlib-DateTime_time {
    display: inline-block;
    width: 6em;
    transition: all 0.15s linear;
  }
  &--noValue > .InfernoFormlib-DateTime_time {
    width: 0!important;
    padding-right: 0!important;
    padding-left: 0!important;
    border-width: 0!important;
  }
}



/*
    ActionBar
*/
.ActionBar,
.InfernoFormlib-ActionBar {
  background-color: rgba(255,255,255,0.8);
  z-index: 1;
  padding: 0.5rem 1rem;
  width: 100%;
  border-top: 1px solid #eee;

  .btn-success {
    margin-right: 1rem;
    color: var(--white);
  }
}
.InfernoFormlib-StickyActionBar {
  position: fixed;
  left: 0;
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
  
  &--hidden {
    display: none;
  }
}


/**
 * FileUploadWidget
 */
 .InfernoFormlib-FileUploadWidget {
  position: relative;
  width: 15rem;
  height: 5rem;
  
  .ProgressContainer {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 1rem;
    transform: translateY(-50%);
    background-color: black;
  }
  .ProgressBar {
    height: 100%;
    background-color: white;
    transition: width 0.3s ease-in-out;
  }
  .DragNDroppable {
    position: relative;
    width: 100%;
    height: 100%;
    border: 4px dashed #ddd;
    border-radius: 0.8rem;
  
    transition: all 0.3s ease-in;
  }
  
  .DragNDroppable--drag {
    background-color: rgba(0,0,0,0.6);
    border-color: rgba(0,0,0,0);
  }
  .DragNDroppable--drag .placeholder {
    color: white;
  }
  
  .Error,
  .Uploading,
  .DragNDroppable--drag-hover {
    background-color: rgba(0,0,0,0.8);
    border-color: rgba(0,0,0,0);
  }
  .DragNDroppable--drag-hover .placeholder {
    color: white;
  }
  
  .ErrorContainer {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%);
  }
  
  .Error p {
    font-size: 0.85em;
    color: white;
    margin-bottom: 0.6em;
  }
  
  .form-control-file {    
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  
  .placeholder {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9em;
    color: #ccc;
  }
}


/*
  ImageField
*/

.ImageField.card {
  flex-direction: row;
  align-items: center;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  
  .card-img {
    border-radius: 0;
    // margin-right: 0.5rem;
    overflow: hidden;
    object-fit: contain;
    max-height: 10rem;
    max-width: 30rem;
    width: auto;
    margin-right: auto!important;
  }
  
  .card-title {
    font-weight: 100;
    font-size: 0.85em;
    margin: 0;
  }
  
  .card-subtitle {
    margin: 0;
    margin-top: 0.2em;
  }
  
  .card-text {
    font-size: 0.8em;
    margin: 0;
    margin-top: 0.2em;
  }
  
  .card-body {
    padding: 0;
    height: 100%;
  }
  
  .card-footer {
    padding: 0;
    background: none;
    border: none; 
  }
  
  .ImageField-Action {
    line-height: 0.8em;
    vertical-align: bottom;
  }
}

.InfernoFormlib-ObjectField {
  display: block;
}

.form-group {
  margin-bottom: 1rem;
  flex-grow: 1;
}

.form-control {
  font-weight: 200;
  font-size: 1.5em;
  padding: 0.1rem 0.5rem;
  border: 0;
  border-radius: 0;
  background-color: #eee;

}
textarea.form-control {
  font-size: 1.3em;
  line-height: 1.15em;
  min-height: 4em;
}
select.form-control {
  font-size: 1.3em;

  option {
    font-size: 0.9em;
  }
}

.form-control-label {
  color: #333;
  font-weight: 200;
  font-size: 0.9em;
  margin-bottom: 0;
}


.InfernoFormlib-ListRow {
  & > .form-control-label {
    font-size: 1.25em;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--gray);
    width: 100%;
  }


  .InfernoFormlib-ListFieldRow {
    display: flex;
    
    .InfernoFormlib-ListFieldRowDeleteBtn.btn-danger {
      background: none;
      color: #dc3545;
      height: 2.5em;
      border: none;
      align-self: center;
    }
    
    .InfernoFormlib-ListFieldRowDeleteBtn:hover {
      background: #dc3545;
      color: var(--white);
    }
  
    & > .form-group {
      flex-grow: 1;
    }
  }
}

.InfernoFormlib-DragItem {
  position: relative;

  .InfernoFormlib-DragHandle {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(../img/icons/drag_handle.svg);
    background-size: contain;
    cursor: grab;
  }
}

.InfernoFormlib-ObjectRow {
  & > .form-control-label {
    border-bottom: 1px solid var(--gray);
    display: block;
    font-size: 1em;
    font-weight: 200;
    margin: 1em 1rem 0.5em 0;
  }

  & > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField {
    border-left: 2px solid var(--primary-40);
    padding-left: 1rem;
  }
}

.sticky-row {
  background-color: white;
  border-top: 1px solid #eee;
}
