body {
  overscroll-behavior-x: none;
}

.App {
  min-width: 300px;
  max-width: 100%;
  overflow-x: hidden;
  background: white; // #222;

  &.public {
  }
}

.placeholder {
  width: 100%;
  position: relative;
}

.placeholder-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #aaa;
}

.placeholder-text h2 {
  text-align: center;
  width: 100%;
}

.placeholder-cta {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.placeholder-cta,
.placeholder-cta a {
  font-size: 2rem;
  color: #aaa;
}

.placeholder-cta a:hover,
.placeholder-cta a:hover .placeholder-cta-arrow {
  font-size: 2rem;
  color: #e83e8c;
  stroke: #e83e8c;
  fill: #e83e8c;
}

.placeholder-cta-arrow {
  stroke: #aaa;
  fill: #aaa;
}

.content {
  background-color: #fff;
}


.login {
  overflow-x: hidden;
}

.menu-profile {
  text-align: center!important;
}

.menu-profile h4 {
  font-weight: lighter;
}

.menu-profile .avatar {
  border-radius: 50%;
  padding: 1rem;
}

.menu-profile .logout {
  margin-bottom: 1rem;
  display: block;
}


.content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh; /* Forcing vertical scrollbar in a way that can be hidden when editing cards */
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.content {
  /* Typography */

  h1 {
    font-weight: 200;
    color: var(--dark);
    margin-bottom: 2rem;
    margin-top: 1rem;
    display: inline-block;
    width: 100%;
  }
}

.login .content {
  transform: translateX(100%);
  opacity: 0.5;
  pointer-events: none;
}

.shortcut {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: #fff;
  z-index: 1;
  border-left: 1px solid #eee;
  color: #ccc;
}

.shortcut:hover {
  text-decoration: none;
  background: #007bff;
  color: #fff;
}


.icon_media::after {
  content: url(img/media.svg);
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  display: inline-block;
  margin: -2rem 1.3rem -0.5rem -0.5rem;
  font-size: 0.3em;
  transform: scale(0.125);
}

.icon_comments::after {
  content: url(img/comments.svg);
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  display: inline-block;
  margin: -2rem 1.3rem -0.5rem -0.5rem;
  font-size: 0.3em;
  transform: scale(0.125);
}

.icon_insights::after {
  content: url(img/insights.svg);
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  display: inline-block;
  margin: -2rem 1.3rem -0.5rem -0.5rem;
  font-size: 0.3em;
  transform: scale(0.125);
}

.icon_settings::after {
  content: url(img/settings.svg);
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  display: inline-block;
  margin: -2rem 1.3rem -0.5rem -0.5rem;
  font-size: 0.3em;
  transform: scale(0.125);
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.login-page {
  position: fixed;
  top: 3.5rem;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-pane {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 25rem;
  text-align: left;
  padding: 1rem;
}

.FadeIn-enter {
  opacity: 0;
  
}
.FadeIn-enter-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-enter-end {
  opacity: 1;
}
/* ----------------------------- */
.FadeIn-leave {
  opacity: 1;
}
.FadeIn-leave-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-leave-end {
  opacity: 0;
}

.Appear-enter {
  opacity: 0;
  height: 0;
}
.Appear-enter-active {
  transition: opacity 0.5s ease-out, height 0.3s ease-out;
}
.Appear-enter-end {
  opacity: 1;
}
/* ----------------------------- */
.Appear-leave {
  opacity: 1;
}
.Appear-leave-active {
  transition: opacity 0.4s ease-out, height 0.3s ease-out;
}
.Appear-leave-end {
  opacity: 0;
  height: 0;
}



.list-item {
  padding: 1rem;
}

.list-item:hover {
  background-color: #eee;
}

.list-item-body {
  flex-grow: 1;
}

.list-item .avatar {
  border-radius: 50%;
  width: 5rem;
}

/**
 * Success message
 */
.SuccessMessage {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.95;
}

.SucessAnimation {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    transition: all 0.15s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    transition: all 0.65s ease-out;
  }
  &-leave-end {
    opacity: 0;
  }
}

/**
 * Page nav
 */

.PageNav {
  &-enter {
    opacity: 0;
    transform: translateX(30%);
  }
  &-enter-active {
    transition: opacity 0.7s ease-out, transform 0.5s ease-out;
  }
  &-enter-end {
    opacity: 1;
    transform: translateX(0);
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity .7s ease-out, transform 0.5s ease-out;
  }
  &-leave-end {
    opacity: 0;
    transform: scale(1.3);
  }
}


/*
  Bootstrap overrides
  TODO: Do this through proper themeing
*/
.App .nav-link,
.App a {
  color: #2196F3;
}