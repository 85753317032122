.Dashboard {
  max-width: 70rem;
  margin-top: 0;
  transition: margin-top 0.2s;
  .content {
    .ListPage.container-fluid {
      margin: 0 auto;
    }
  }
}

@media (max-width: 576px) {
  .isAdminRoot .Dashboard {
    display: none;
  }
}

.placeholder {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.PageLogo {
  position: fixed;
  right: 1rem;
  bottom: 0;
  width: 5rem;
  color: #C56174;
  transition: transform 0.3s ease-in-out;

  &:hover {
    color: #618EC5;
    transform: scale(1.05);
  }
}