.ActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.95);
}

.ActionBarContent {
  display: flex;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  i {
    vertical-align: -2px;
    color: #aaa;
  }

  .ActionBarContent-SecondaryActions {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    
    .btn-link {
      text-decoration: none;
    }
    
    .status {
      text-align: right;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      color: #ccc;
      margin-bottom: 0;
    }

    .TimedPublishing,
    .WorkflowStatus {
      display: flex;
      align-items: center;

      h3 {
        font-size: 0.85em;
        margin-bottom: 0;
      }

      .dropdown-header {
        font-size: 1.2em;
      }
      .status.dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        transform: rotate(180deg);
      }
    }

    .TimedPublishing {
      .Icon-Clock {
        height: 1.5em;
        width: 1.5em;
        vertical-align: -0.35em;
        color: #ccc;
        fill: currentColor;
        stroke: currentColor;

        &.active {
          color: var(--primary);
        }
      }

      .nav-link:hover .Icon-Clock,
      .nav-link:focus .Icon-Clock {
        color: white;
      }
    }
  }
}

