.MediaWidget {
  max-width: 100%!important;
  margin: 1rem!important;
  height: calc(100vh - 2rem);

  .modal-content {
    height: 100%;
  }
}

.MediaSelector {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.MediaSelectItem {    
  &:hover {
    background-color: #ddd;
    cursor: pointer;
  
  }
}

.MediaSelectDetails {

  &.card {
    border: none;
  }

  .card-body {
    padding: 0.35rem;
  }

  .card-footer {
    background: none;
    padding: 0.25rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }
  
  .form-control-label {
    font-size: 0.6em;
    font-weight: 400;
  }

  .form-control {
    border: none;
    padding: 0;
    line-height: 1.5;
    height: auto;
    font-size: 0.8em;
  }
}



.ModalMenu {
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}