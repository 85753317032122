/*
  StickyRow
*/
.sticky-row {
  position: fixed;
  bottom: 0;
}
.row--hidden {
  visibility: hidden!important;
}
.sticky-row--hidden {
  display: none!important;
}