.LoginWidget {
  position: relative;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.25);

  #FB-Login-Btn {
    margin-top: 1rem;
  }
  
  #Login-Logo {
    position: absolute;
    left: 50%;
    top: -4.5rem;
    transform: translateX(-50%);
    width: 8rem;
  }

  .row {
    justify-content: center;
  }

  .card-footer {
    text-align: center;
  }
  .LoginWidget-Action {
    justify-content: center;
  }

  .LoginWidget-Separator {
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0%;
      right: 0%;
      height: 0;
      border-bottom: 1px solid #ccc;
    }

    i {
      z-index: 0;
      padding: 0 1rem;
      background-color: #fff;
      color: #999;
    }

  }

  .LoginWidget-Social {
    margin-top: 2rem;
    margin-bottom: 0.75rem;
  }
 
  .LoginWidget-Button {
  }
}

.LoginWidget-SeparatorText {
  font-size: 1.25rem;
  color: #ccc;
  text-align: center;
  font-weight: 100;
  font-style: italic;
}

.LoginWidget-Alternative {
  text-align: center;
}

