.ActionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.95);
}

.ActionBarContent {
  display: flex;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;

  i {
    vertical-align: -2px;
    color: #aaa;
  }

  .ActionBarContent-SecondaryActions {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    
    .btn-link {
      text-decoration: none;
    }
    
    .status {
      text-align: right;
      font-size: 1.5em;
      font-weight: 600;
      text-transform: uppercase;
      color: #ccc;
      margin-bottom: 0;
    }

    .WorkflowStatus {
      display: flex;
      align-items: center;

      h3 {
        font-size: 0.85em;
        margin-bottom: 0;
      }

      .dropdown-header {
        font-size: 1.2em;
      }
      .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        transform: rotate(180deg);
      }
    }
  }
}

.EditPage {
  h2 {
    margin: 1em auto;
    font-size: 1.5em;
    max-width: 40rem;
    color: #999;
  }

  h3 {
    text-align: center;
    font-size: 1.2em;
    width: 100%;
  }
}

.form-header {
  position: fixed!important;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  z-index: 9;
  border-bottom: 1px solid #eee;
  background-color: rgba(255,255,255, 0.95);

  &.btn-group > .btn {
    flex-grow: 0;
  }

  .CloseButton {
    margin-right: auto;
  }
}

/* Chrome for editing modal, should be refactored into a single place */
.modal {
  background: rgba(255,255,255,0.6);

  &.fade .Lightbox.modal-dialog,
  &.fade .EditModal.modal-dialog {
    transform: translate(5rem, 0);
  }

  &.show .Lightbox.modal-dialog,
  &.fade .EditModal.modal-dialog {
    transform: translate(0, 0);
  }
}

.Lightbox.modal-dialog,
.EditModal.modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  max-width: 100%;


  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    border-left: 1px solid #ddd;
  }

  .InfernoFormlib-FormRows {
    max-width: 50rem;
    margin: 0 auto;
  }

  .ImageField.card {
    flex-direction: column;
  }

  .card-img {
    margin-right: 0;
  }

  .card-body {
    width: 100%;
  }

  .card-footer {
    background-color: #eee;
    text-align: center;
  }

  .ImageField-Action {
    display: inline-block;
    padding: 0.5rem;

    &:hover {
      background-color: rgba(255,255,255,0.6);
    }
  }
}