.ContentPage {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  min-height: 100vh;
  
  @media (max-width: 576px) {
    padding-top: 3.5rem;
  }

  #Page-Logo {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    top: 1rem;
    opacity: 1;
    transition: 0.2s ease-in;
  
    @media (max-width: 80rem) and (min-width: 576px) {
      transform: translateX(0);
      left: 0.9rem;
      top: 0.7rem;
      width: 7rem;
      opacity: 1;
    }
  }

  h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%;
  }

  h3 {
    margin: 2.5rem 0 2rem;
    font-size: 1.85em;
    text-align: center;
    width: 100%;
  }

  .container-fluid {
    max-width: 50rem;

    .col {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .Link-Button {
    margin: 1rem auto;
  }

  .Button {
    margin: 2rem auto 1rem;
    padding: 0.5rem 1.5rem;

    @media (min-width: 576px) {
      margin-top: 4rem;
    }
  } 

}