.SearchWidget {
  position: relative;

  .form-control {
    padding-right: 2.5rem;
  }

  .icon {
    width: auto;
    position: absolute;
    top: 0;
    right: 8px;
    height: 70%;
    margin-top: 0.3rem;
    color: #ddd;
    pointer-events: none;
    
    opacity: 0.4;
    fill: currentColor;
  }

  .search & .icon {
    opacity: 1;
  }
}