.Page {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  max-width: 60rem;
  margin: 0 auto;
}

.TopMenu-Container {
  position: fixed;
  background-color: #EB4631;
  height: 60px;
  width: 100%;
  max-width: 60rem;
  box-shadow: 0 2px 6px rgba(0,0,0,0.20);
  z-index: 99;

  .TopMenu {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 52rem;
    height: 100%;
    margin: 0 auto;
  }

  .Page-Logo {
    display: flex;
    margin: 0 auto;
    height: 40px;

    a {
      display: block;
    }
    img {
      height: 100%;
    }
  }
  .LogoutMenu {
    // TODO: Activate this if needed
    display: none!important;
    top: 50%;
    transform: translateY(-50%);
    
    .nav-link {
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #EB4631!important;
      }
    }
  }
}

.Page-Body {
  padding-top: 60px;
}

.TopMenu-WeekNr {
  position: absolute;
  left: 1rem;
  font-family: 'Helvetica Nueue', Helvetica, sans-serif;
  font-size: 1.3rem;
  color: #fff;
}