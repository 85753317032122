.PageFooterContainer {
  width: calc(100% + 0.2rem);
  margin: auto auto 0 auto;
  text-align: left;
  max-width: 50rem;

  a {
    display: block;
    position: relative;
    background-color: #eee;
    color: #2196F3;
    padding: 0.5rem 1rem;
    display: inline-block;
    width: calc(50% - 0.2rem);
    margin: 0 0.1rem 0.2rem;
    text-align: left;

    @media (max-width: 576px) {
      max-width: 100%;
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0; 
      height: 0; 
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      
      border-left: 8px solid #2196F3;
    }
  }
}

.PageFooter {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EB4631;
  margin: 0 0.1rem;
  width: calc(100% - 0.2rem);
}

.PageFooterBody {
  font-weight: 100;
  color: #fff;
  font-size: 0.75em;
  padding: 0.5em;
  line-height: 1.25em;

  @media (max-width: 576px) {
    padding: 0.5em;
    font-size: 0.9em;
  }
}