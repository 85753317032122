.GalleryLayoutField {
  svg {
    color: #ccc;
    width: 3rem;
    height: 3rem;
  }

  .nav-link {
    background-color: transparent!important;

    svg {
      color: #ccc;
    }

    &:hover svg,
    &.active svg {
      color: var(--primary);
    }
  }

}