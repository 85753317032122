.MainMenuContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}

.AdminMenu,
.ModalMenu,
.MainMenu {
  .nav-link,
  .nav-link.active,
  .nav-link:hover {
    white-space: nowrap;
    border-radius: 0;
    background: none;
    border-bottom: 3px solid rgba(0,0,0,0); /* Added this to solve strange change in width on hover */

    /* Make sure menu fits at different sizes */
    @media (min-width: 577px) {
      font-size: 0.8em;
      line-height: 2.4em;
    }
    @media (min-width: 702px) {
      font-size: 1em;
    }
    @media (min-width: 810px) {
      font-size: 1em;
    }
  }

  .nav-link:hover {
    border-bottom: 3px solid #2196F366;
  }
  
  .nav-link.active,
  .nav-link.active:hover {
    border-bottom: 3px solid #2196F3;
  }
}



.MainMenu {
  z-index: 99;
  position: fixed;
  top: 0;
  display: flex;
  font-size: 1.25em;
  max-width: 70rem;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 6px rgba(0,0,0,0.15);

  .AvatarWrapper {
    width: 3rem;
    height: 3rem;
    margin: 0 0 0 0.2rem;
    align-self: center;
    flex-shrink: 0;
  }

  .Avatar {
    width: 100%;
    height: 100%;

    &-animating {
      transition: transform 0.4s, opacity 0.6s;
    }
  }

  .MainMenu-Background {
    position: absolute;
    left: -1rem;
    top: 0;
    right: -1rem;
    height: 100%;
    background: var(--white);
    z-index: -1;
  }
  
  .MainMenu-Slot {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: center;

    &:first-child > span { margin-right: auto; }
    &:last-child  > span { margin-left: auto;  }

    .nav {
      flex-wrap: nowrap;
    }
  }

  .LeftSlot {
    justify-content: left;
  }

  .MiddleSlot {
    justify-content: center;
    width: 0;
    z-index: 9;
  }

  .RightSlot {
    justify-content: right;
    padding: 6px 0;
  }

  .SearchContainer {
    width: 12rem;
    padding: 0 5px;
    margin-left: auto;
    transition: width 0.3s, margin 0.3s;

    @media (max-width: 576px) {
      width: 3.5rem;
    }
  }
  &.search .SearchContainer {
    width: 100vw;
    #search__Field {
      width: calc(100vw - 10px);
      background-color: rgba(255,255,255,0.9);
    }
  }

  .LogoutMenu {
    position: relative;
    margin-left: auto;
    flex-grow: 0;
    top: 0;
    right: 0;
    z-index: 99;
  }
}

.TopMenuContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  .AdminMenu {
    flex-grow: 0;
    margin-right: 0.25rem;

    .nav-link,
    .nav-link.active,
    .nav-link:hover {
      color: #2196F3;
      text-shadow: 0.5px 0.5px rgba(255,255,255,0.4);
    }
  }

  .LogoutMenu {
    flex-grow: 0;
  }
}

.SearchHeader {
  font-size: 1.25rem;
  margin: -3rem auto 3rem;
  max-width: 40rem;
}

.SearchContainer {

  .InfernoFormlib-FormRows {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;  
  }
  
  #search__Field {
    color: #49c0e7;
    max-width: 40rem;
    opacity: 0.75;
    background: transparent;
    border: 1px solid #eaeaea;
    border-radius: 2em;
    transition: margin 0.3s, width 0.3s, opacity 0.5s;
    width: 7rem;

    @media (max-width: 576px) {
      width: 3rem;
    }

    &::placeholder {
      color: #ddd;
    }

    &.form-control {
      font-weight: 300;
    }

    &:focus {
      opacity: 1;

      @media (min-width: 576px) {
        width: 12rem;
      }
    }
  }
  
  .sticky-row {
    background: none;
    bottom: auto;
    z-index: 9;
    border-top: none;

    .form-nav,
    form {
      display: none;
      background: rgba(255,255,255,0.8);
    }
  }

  .form-nav,
  form {
    padding: 8px 0 0;
    background: white;
  }

  .form-nav {
    justify-content: center;
  }

  .form-group {
    margin-bottom: 5px;
  }
}

.NavBar {
  &-enter {
    opacity: 0;
    
  }
  &-enter-active {
    transition: opacity 0.5s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 0.3s ease-in;
  }
  &-leave-end {
    opacity: 0;
  }
}

/*
  Mobile overrides
*/
.MainMenu-PageNav {
  display: none;
}

@media (max-width: 576px) {
  .MainMenu {
    flex-direction: column;

    .AvatarWrapper {
      padding: 0.3rem calc(100% - 2.7rem) 0.3rem 0.3rem;
      box-shadow: 0 0 4px rgba(0,0,0,0.2);
      width: 100%;
      margin: 0;
    }
    
    .MainMenu-Slot.LeftSlot {
      flex-direction: column;
      align-items: stretch;
    
      .nav {
        flex-direction: column;
      }
    }
  
    .MainMenu-PageNav {
      display: flex;
    }

    .LogoutMenu {
      position: absolute;
      top: 0;
      right: 0;
    }


    .MainMenu-Slot.LeftSlot,
    .MainMenu-Slot.MiddleSlot,
    .MainMenu-Slot.RightSlot {
      display: none;
    }

    &.isAdminRoot {
      .MainMenu-Slot.LeftSlot,
      .MainMenu-Slot.MiddleSlot,
      .MainMenu-Slot.RightSlot {
        display: flex;
      }

      .MainMenu-PageNav {
        display: none;
      }
    }
  
  }
}