// https://developers.facebook.com/docs/facebook-login/web
.fb-btn {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 8px 0 32px;
  height: 28px;
  background-color: #1877f2;
  max-width: 320px;
  min-width: initial;
  width: auto;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1!important;
  }

  .block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .fb-btn-text {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: .25px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 1.28;
    user-select: none;
    text-overflow: clip;
    overflow: hidden;
  }

  &::after {
    content: '';
    background-image: url(../img/external/facebook-f-32.png);
    position: absolute;
    left: 8px;
    top: 6px;
    width: 16px;
    height: 16px;
    background-size: contain;
  }

  // LARGE
  &.fb-btn-large {
    padding: 0 12px 0 44px;
    height: 40px;
    max-width: 400px;
    border-radius: 4px;
    opacity: 0.85;

    .fb-btn-text {
      font-size: 16px;
    }

    &::after {
      content: '';
      background-image: url(../img/external/facebook-f-48.png);
      position: absolute;
      left: 12px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-size: contain;
    }
  }

  // SMALL
  &.fb-btn-small {
    padding: 0 8px 0 24px;
    height: 20px;
    max-width: 200px;
    border-radius: 3px;
    opacity: 1;

    .fb-btn-text {
      font-size: 11px;
      padding-top: 2px;
      padding-left: 2px;
      max-width: 150px;
    }

    &::after {
      content: '';
      background-image: url(../img/external/facebook-f-24.png);
      position: absolute;
      left: 8px;
      top: 4px;
      width: 12px;
      height: 12px;
      background-size: contain;
    }
  }
}