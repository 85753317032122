.btn.has-icon {
  .icon {
    width: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  &.btn-lg .icon {
    width: 2rem;
  }

  label {
    vertical-align: -5%;
    margin: 0;
    padding: 0;
  }
}

.btn-primary.fake-disabled {
  color: rgba(255, 255, 255, 0.85);
  background-color: #A5A5A5;
  border-color: #A5A5A5;
  opacity: 0.65;
}