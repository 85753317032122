@charset "UTF-8";
.MenuNavigation {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000; }

.Content {
  padding-top: 40px; }

.Edit-SubNav {
  margin-top: -1rem;
  margin-bottom: 1rem; }

.Medium-placeholder {
  position: absolute;
  pointer-events: none;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.Medium-rich {
  -webkit-user-modify: read-write;
  -webkit-user-select: text;
  user-select: text;
  text-align: left;
  padding: 0.5rem 2rem;
  font-size: 1.5em; }

.RichEditor-StickyFormattingToolbar {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 1; }

.RichEditor-StickyFormattingToolbar--hidden {
  display: none; }

@media (max-width: 500px) {
  .RichEditor-FormattingToolbarContainer {
    font-size: 12px; } }

.RichEditor-FormattingToolbar {
  margin-bottom: 1em; }

.RichEditor-FormattingButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: gray;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-FormattingButton:hover {
  background-color: darkgray; }

.RichEditor-InsertActionButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #555;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-InsertActionButton:hover {
  background-color: darkgray; }

.RichEditor-WidgetButton {
  padding: 0.8em 1em;
  margin-right: 1px;
  background: #333;
  display: inline-block;
  color: white;
  cursor: pointer;
  min-width: 3em;
  text-align: center; }

.RichEditor-WidgetButton:hover {
  background-color: #222; }

.RichText-Widget {
  position: relative; }

.RichText-WidgetButtonToolbar {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem; }

.RichText-WidgetFormattingButton {
  display: inline-block;
  width: 2em;
  height: 2em;
  padding: 0.2em;
  margin-left: 0.5em;
  border: 1px solid #fff;
  border-radius: 0.2em;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out; }

.RichText-WidgetFormattingButton:hover {
  opacity: 1;
  background-color: white; }

.RichText-Widget:hover .RichText-WidgetFormattingButton {
  opacity: 1; }

.RichText-DeleteButton {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em; }

.RichText-EditButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em; }

.ResultTableModal-TableContainer {
  padding: 3em 0; }

.ResultTableModal-TableContainer .ResultTableModal-MediumEditor {
  display: block;
  width: 100%; }

.InfernoFormlib-CancelBtn {
  margin-left: 1.5rem;
  vertical-align: middle; }

.RichText-PodcastContainer {
  height: 20rem; }

/* CONTENT STYLING AND TYPOGRAPHY */
/* Styling of content starts here! */
.Article-Header_2,
.Article-Header_3,
.Article-Header_4 {
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-weight: bold; }

.Article-Header_2 {
  font-size: 1.6em; }

.Article-Header_3 {
  font-size: 1.3em; }

.Article-Header_4 {
  font-size: 1.3em;
  color: #444;
  margin-top: 3em; }

.Article-Paragraph {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1em; }

.Article-Quote {
  display: block;
  font-size: 2rem;
  margin: 1em 0;
  padding-left: 1em;
  background: #f9f9f9;
  border-left: 1rem solid #ccc;
  quotes: "“" "”" "‘" "’"; }

.Article-Quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0;
  margin-right: 0.1em;
  vertical-align: -0.45em; }

.Article-UnorderedList {
  font-size: 1em;
  line-height: 1.5em;
  margin: 1em 0; }

.Article-UnorderedList-Item {
  font-size: 1em;
  margin-bottom: 0.5em;
  list-style-type: circle;
  list-style-position: inside; }

img {
  width: 100%; }

.RichText-Widget-left {
  float: left;
  margin: 0 1em 0.6em 0;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-right {
  float: right;
  margin: 0 0 0.6em 1em;
  width: 40%;
  min-width: 15rem; }

.RichText-Widget-center {
  display: block;
  text-align: center;
  margin: 2em 0; }

.RichText-Widget-inline {
  display: inline-block; }

.RichText-ImageContainer {
  position: relative; }

.RichText-Image {
  width: 100%; }

.RichText-PodcastContainer {
  position: relative; }

.RichText-Podcast {
  height: 21rem; }

.RichText-YoutubeContainer {
  position: relative; }

.RichText-Youtube {
  height: 21rem; }

.RichText-MediaContainer {
  position: relative; }

.RichText-ResultTable {
  width: 100%;
  counter-reset: resultTableNr;
  margin: 2rem 0 4rem; }

.RichText-ResultTableRow {
  border-bottom: 1px solid #ccc; }

.RichText-ResultTableCell {
  min-height: 1em;
  padding: 1rem 0.5rem 0.5rem; }

.RichText-ResultTableCell-Name {
  width: 70%;
  padding-left: 4rem; }

.RichText-ResultTableCell-Name:before {
  position: absolute;
  left: 0rem;
  width: 2.7rem;
  counter-increment: resultTableNr;
  content: counter(resultTableNr) "."; }

.RichText-ResultTableCell-Result {
  width: 30%;
  min-width: 5em;
  text-align: right; }

/***************
** Modal CSS **
***************/
.modal {
  display: block; }

.ModalFade-leave {
  transform: translateY(0);
  opacity: 1; }

.ModalFade-leave-active {
  overflow: hidden;
  transition: transform 0.5s ease-in, opacity 0.4s ease-in; }

.ModalFade-leave-end {
  transform: translateY(-150%);
  opacity: 0; }

.ModalFade-enter {
  transform: translateY(-150%);
  opacity: 0; }

.ModalFade-enter-active {
  transition: transform 0.5s ease-out, opacity 0.4s ease-in; }

.ModalFade-enter-end {
  transform: translateY(0);
  opacity: 1; }

/* The modal background */
.ModalBackdropFade-leave {
  opacity: 0.5; }

.ModalBackdropFade-leave-active {
  overflow: hidden;
  transition: opacity 0.3s ease-in; }

.ModalBackdropFade-leave-end {
  opacity: 0 !important; }

.ModalBackdropFade-enter {
  opacity: 0 !important; }

.ModalBackdropFade-enter-active {
  transition: opacity 0.3s ease-in; }

.ModalBackdropFade-enter-end {
  opacity: 0.5; }

/***************************
 ** Form specific styling **
 ***************************/
#influencer\.contactInfo__Field,
#influencer\.deliveryAddress__Field,
#influencer\.invoiceDetails__Field {
  border-left: none;
  padding-left: 0; }

#influencer\.subCategories__Field .InfernoFormlib-ListFieldRowDeleteBtnWrapper {
  display: none; }

/* Edit Pages should cover list pages */
.ViewPage,
.EditModal,
.EditPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 99; }

.ViewPage,
.EditModal,
.IEditItem {
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 3.6rem; }
  .ViewPage .form-nav,
  .EditModal .form-nav,
  .IEditItem .form-nav {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center; }
  .ViewPage h1,
  .ViewPage .Mobiledoc,
  .ViewPage .form-group,
  .ViewPage .form-check,
  .ViewPage .__mobiledoc-editor,
  .EditModal h1,
  .EditModal .Mobiledoc,
  .EditModal .form-group,
  .EditModal .form-check,
  .EditModal .__mobiledoc-editor,
  .IEditItem h1,
  .IEditItem .Mobiledoc,
  .IEditItem .form-group,
  .IEditItem .form-check,
  .IEditItem .__mobiledoc-editor {
    display: block;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem; }
  .ViewPage .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField,
  .EditModal .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField,
  .IEditItem .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField {
    border-left: 4px solid var(--primary-20);
    padding-left: 1rem; }
  .ViewPage > .InfernoFormlib-FormRows > .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField,
  .EditModal > .InfernoFormlib-FormRows > .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField,
  .IEditItem > .InfernoFormlib-FormRows > .InfernoFormlib-ObjectRow > .InfernoFormlib-RowFieldContainer > .InfernoFormlib-ObjectField {
    border-width: 0;
    padding-left: 0; }

#influencer\.contactInfo\.phone__Field {
  max-width: 10em; }

#influencer\.invoiceDetails\.companyVatNr__Row {
  max-width: 13.5em; }

#influencer\.deliveryAddress\.postalCode__Row {
  display: inline-block;
  max-width: 8em;
  margin-right: 1.85rem; }

#influencer\.deliveryAddress\.city__Row {
  display: inline-block;
  max-width: 22em;
  width: 100%; }
