.list-item.card.media {
  height: fit-content;
  padding: 0.5rem;
  border: 0!important;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  
  &.loaded {
    opacity: 1;
  }

  .masonry-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    
    .media-body {
      max-height: 4em;
    }
    caption {
      padding: 0;
      text-align: center;
      color: white;
      text-shadow: rgba(0,0,0,0.6) 2px 2px 4px;
    }
    .thumbnail {
      object-fit: contain!important;
      height: 100%;
      border-radius: 0;
      border-radius: 2px;
      max-width: 100%;
    }
  }
}

.RelatedItem {
  &.media {
    margin-left: -15px;
    margin-right: -15px;
  }
  .thumbnail {
    cursor: pointer;
    width: 120px;
  }
  h3, p {
    cursor: pointer;
  }
}