.HeroContainer {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #222423;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: -6rem;
    
  .HeroBody {
    text-align: center;
    
    h2 {
      font-size: 2.5em;
      transform: rotate(2deg);
      font-weight: 100;
      margin-bottom: 0.2rem;
    }
    
    p {
      font-size: 1.4em;
      font-weight: 100;
      margin-bottom: -0.2rem;
    }
    
    .btn {
      font-size: 1.4em;
      font-weight: 100;
      padding: 0;
      background: transparent;
      border: none;
      margin: 1em 0;
    }
    
    .btn:hover {
      background: transparent;
      border: none;
    }

    @media (max-width: 576px) {
      h2 {
        font-size: 2em;
      }
      p {
        font-size: 1em;
      }
    }
  }
  
  .HeroBody-Stripe {
    display: inline-block;
    font-family: 'Open Sans', Roboto, sans-serif;
    text-align: center;
    padding: 0.1rem 0.4rem 0.3rem;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;

    /* Image specific */
    background-color: #c56074a6;
    color: #fff;
  }

  .btn:hover .HeroBody-Stripe {
    -webkit-box-shadow: 4px 4px 15px 0px rgba(0,0,0,0.32);
    -moz-box-shadow: 4px 4px 15px 0px rgba(0,0,0,0.32);
    box-shadow: 4px 4px 15px 0px rgba(0,0,0,0.32);
    transform: translate(-2px, -2px);

    background-color: #c56074db;
  }
}

