.RelationField-User.list-item {
  margin: -0.6rem;
  padding: 0.6rem;

  &:hover {
    color: #49C0E7;
    h3 {
      color: #49C0E7;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  &.media {
    .thumbnail.avatar {
      width: 2rem;
      align-self: center;
    }
  }
}