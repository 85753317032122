.Fullpage {
  padding-top: 6rem;
  min-height: 100vh;
  background-color: white;
  box-shadow: 0 0 5px rgba(0,0,0,0.15);
  
  display: flex;
  flex-direction: column;
}

.LogoutMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;

  .nav-link {
    text-shadow: 0.5px 0.5px rgba(255,255,255,0.4);
  }

  @media (max-width: 576px) {
    display: none!important;
  }
}

.Hero {
  position: relative;
  max-width: 50rem;
  padding: 3rem 2rem 2rem;
  margin: 0 auto;

  .Hero-Divider {
    position: absolute;
    bottom: 0;
    width: calc(100% - 8rem);
    margin: 0 2rem;
  }

  .Hero-Shadow {
    position: absolute;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0,0,0,0.20);
    top: 6px;
    height: 4px;
  }
  .Hero-Blockers {
    position: absolute;
    width: calc(100% + 2rem);
    margin: 0 -1rem;
    background-color: #fff;
    height: 10px;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  max-width: 48rem;
  margin: 3rem auto;
  column-gap: 1rem;
  padding: 0 1rem;

  .Content-Header {
    h1 {
      font-size: 1.5rem;
      color: #2196F3;
    }
  }

  .Content-Section {
    flex-basis: 50%;
    column-count: 2;
    column-gap: 2rem;

    b {
      color: #2196F3;
    }

    @media (max-width: 576px) {
      column-count: 1;
    }
  }

  .Content-AppStore {
    padding: 5rem 2rem 3rem;

    img {
      display: block;
      max-width: 10rem;
      margin: 0 auto;
    }
  }
}