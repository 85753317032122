.thumbnail {
  position: relative;
  object-fit: contain;

  .icon {
    position: absolute;
    top: 0px;
    right: 3px;
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
    opacity: 0.8; 
  }
}