@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Roboto:200,300,400,500');
@import './bootstrapTheme/_variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './bootstrapTheme/_buttons.scss';

.btn-primary {
  color: rgba(255,255,255,0.85);
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.ContactInfo ul {
  padding: 0;
}

.ContactInfo li {
  list-style: none;
}
