.MediaCard {
  position: relative;
  display: inline-block;

  &.media {
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    vertical-align: top;
  }

  .ImageField.card {
    flex-direction: row;
  }

  .card-img {
    width: 100%;
  }

  .media-body {
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
    align-self: center;
    padding: 0.25em 1em 0.5em;
    margin-bottom: 0.75em;
    max-width: 80%;
    color: var(--gray);
  }
}

/* Chrome for editing modal, should be refactored into a single place */
.modal {
  background: rgba(255,255,255,0.6);

  &.fade .MediaCardModal.modal-dialog {
    transform: translate(5rem, 0);
  }

  &.show .MediaCardModal.modal-dialog {
    transform: translate(0, 0);
  }
}

.MediaCardModal.modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  max-width: 100%;


  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    border-left: 1px solid #ddd;
  }


  .InfernoFormlib-FormRows {
    max-width: 50rem;
    margin: 0 auto;
  }

  .ImageField.card {
    flex-direction: column;
  }

  .card-img {
    margin-right: 0;
    height: 10rem;
    width: auto;
    margin-right: auto;
  }

  .card-body {
    width: 100%;
  }

  .card-footer {
    text-align: left;
  }

  .ImageField-Action {
    display: inline-block;
    padding: 0.5rem;

    &:hover {
      background-color: rgba(255,255,255,0.6);
    }
  }
}
