.masonry-container {
  grid-auto-rows: 0;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat( auto-fit, minmax(15rem, 1fr) );
}

.masonry-item-container {
  height: fit-content;
  padding: 0.5rem;
  border: 0!important;
  opacity: 0;
  transition: opacity 0.5s ease-in;

  &.loaded {
    opacity: 1;
  }
  
  .masonry-item {
    position: relative;
    overflow: hidden;
    width: 100%;

    & > img {
      object-fit: contain;
      width: 100%;
    }
    
    .media-body {
      max-height: 4em;
    }
    caption {
      padding: 0;
      text-align: center;
      color: white;
      text-shadow: rgba(0,0,0,0.6) 2px 2px 4px;
    }
  }

  .thumbnail {
    max-width: 100%;
  }

  .media-body {
    font-size: 0.75em;
    flex: none;
    margin-top: auto;
    display: flex;
    position: absolute;
    bottom: 0;
    padding: 0.25rem;
  }

  caption {
    padding: 0;
  }
}