.Mention {
  position: relative;
  background-color: #999;
  color: white;
  padding: 0.1rem 0.35rem 0.1rem 0.3rem;
  border-radius: 4px;

  .DropdownList {
    position: absolute;
    left: 0;
    color: black;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .DropdownListItem {
    padding: 0.5rem;
    min-width: 10rem;

    &:hover {
      background-color: var(--primary);
      color: var(--white);
    }
  }
}

