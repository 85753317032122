.section-onboarding {
  .top-bar {
    height: 0;
    overflow: hidden;
  }
}

/* General columns rule */
.row.columns-2 {
  display: block;
  column-count: 2;
  column-fill: auto;
  column-gap: 30px;
  padding: 0 15px;

  @media (max-width: 576px) {
    column-count: 1;
  }
}

.Onboarding {
  display: block;
  padding-top: 3.5rem;

  #Page-Logo {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    top: 1rem;
    opacity: 1;
    transition: 0.2s ease-in;
  
    @media (max-width: 80rem) and (min-width: 576px) {
      transform: translateX(0);
      left: 0.9rem;
      top: 0.7rem;
      width: 7rem;
      opacity: 1;
    }
  }

  h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
    width: 100%;
  }

  h4 {
    font-size: 1em;
  }
  .keep-together {
    break-inside: avoid;
    display: block;
  }

  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container-fluid {
    max-width: 50rem;

    .col {
      max-width: 35rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .Onboarding-Link-Button {
    margin: 1rem auto;
  }

  .Onboarding-ActionContainer {
    display: flex;
    padding: 2rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;

    #FB-Button {
      margin: 0 auto 0.5rem;
    }

    @media (min-width: 576px) {
      padding-top: 4rem;
    }
  }

  .Invitation-Message {
    margin-top: 1rem;
  }

  .LoginWidget-Social {
    padding: 2rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;
    justify-content: center;

    #FB-Button {
      margin: 0 auto 0.5rem;
    }

    @media (min-width: 576px) {
      _padding-top: 4rem;
    }
  }

  .Onboarding-Button {
    margin: 0 auto 0.5rem;
    padding: 0.5rem 1.5rem;
  }

  .Onboarding-Action-Button {
    min-width: 15rem;
  }

  .Onboarding-Avatar {
    display: block;
    width: 30%;
    margin: 4em auto;
    border-radius: 50%;
  }


  .Onboarding-AcceptTerms {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;
    
    .Onboarding-Button {
      margin: 0 0 0.5rem;
    }

    @media (min-width: 576px) {
      flex-direction: row;
      border-top: 1px solid #eee;

      ._InputContainer {
        text-align: right;
      }

      ._ButtonContainer {
        text-align: left;
      }

      .form-group {
        margin-bottom: 0.5rem;
      }
    }

    ._InputContainer {
      .form-group {
        display: inline-block;
        position: relative;
        align-items: center;
      }
      .form-check-input {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        margin: 0;
      }
      .form-control-label {
        margin-left: 1.5rem;
        vertical-align: 1px;
      }
    }

  }

  .Onboarding-VerifyEmail {
    .Onboarding-Button {
      margin-top: 1rem;
    }
  } 
}
.RegisterStep-VideoContainer {
  position: relative;
  margin: 0 -15px;  
}

.RegisterStep-Video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.RegisterStep-BlockVideo {
  padding-top: 56.2%;
  width: 100%;
}

.modal {

  .modal-footer {
    justify-content: center;
  }

  .modal-footer .btn {
    flex: 1;

    @media (min-width: 576px) {
      max-width: 8rem;
    }
  }
}