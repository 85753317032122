.list-item-body {
  a {
    color: var(--gray);
  }

  h3 {
    font-size: 0.75em;
    color: #aaa;

    i {
      font-style: normal;
      font-weight: 700;
    }
  }
}

.list-item-workflow.col {
  .Icon-Clock {
    vertical-align: -3px;
    width: 1em;
    height: 1em;
    margin-right: 0.25rem;
  }

  .status {
    text-align: right;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: -0.25em;
  }
  
  .actions {
    font-size: 0.75em;
    
    .nav-link,
    .nav-link.active,
    .nav-link:hover {
      border-radius: 0.25rem;
      padding: 0.25em 0.5em;
    }
    
    .nav-link.active,
    .nav-link.active:hover {
      border-bottom: 3px solid var(--primary);
    }
  }
}  