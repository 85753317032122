.EditModal,
.EditPage {
  padding-top: 3rem;
  .Toolbar {
    position: fixed;

    .Actions {
      max-width: 80rem;
      width: 100%;
    }

    .CloseButton {
      flex-grow: 0;
      flex-shrink: 0;
      width: 6rem;
      margin-right: auto;
    }
    .spacer {
      width: 6rem;
      margin-left: auto;
    }
  }

  .InfernoFormlib-ActionBarContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    margin-left: -15px;
    z-index: 99;

    .InfernoFormlib-ActionBar {
      .col {
        align-items: center;
      }
    }
  }
}

.EditModal,
.GuidePage,
.LivePage,
.EditPage {
  label#title {
    display: none;
  }

  h1,
  #title__Field {
    border: none;
    padding: 0;
    font-size: 2em;
    text-align: center;
  }

  .Mobiledoc,
  .InfernoFormlib-FormRows {
    overscroll-behavior: contain;
    max-width: 40rem;
    margin: 0 auto;
  }

  .Toolbar {
    margin: 0 auto;
  }
}

.Toolbar {
  background-color: var(--white);
  margin-left: -15px;
  text-align: center;

  .shadow-block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px;
    right: -8px;
    background: white;
  }

  .ParagraphStyle {
    width: 7rem;

    .dropdown-toggle::after {
      margin-left: 1em;
    }

    &.show .dropdown-toggle {
      color: var(--secondary);
      background: var(--primary-20);
    }
  }

  .btn-group {
    justify-content: center;
    margin: 0 0.2rem;
  }

  .btn {
    padding: 0.35rem 0.45rem;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  .btn-secondary {
    color: var(--secondary);
    background-color: var(--white);
    border-color: var(--white);
    border-width: 0;

    &:not(:disabled):not(.disabled).active {
      color: var(--white);
      background-color: var(--primary-20);    
    }

    &:hover {
      color: var(--secondary);
      background-color: var(--primary-20);
      border-width: 0;
    }

  }
}

/* START/ Rich text content typography */
.Mobiledoc,
.__mobiledoc-editor {
  blockquote {
    border-left: 3px solid var(--primary);
    padding: 1rem;
    margin-left: 0.5rem;
    color: var(--secondary);
    padding-top: 0.35em;
    padding-bottom: 0.35em;
  }
}
/* /END Rich text content typography */
