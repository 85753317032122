.Toolbar {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 80rem;
  margin: 0 auto;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
}

.Toolbar .btn {
  border-radius: 0!important;
}

.Toolbar .dropdown {
  margin: 0 0.5rem;
}

.__mobiledoc-editor {
  min-height: 20vh;
  margin-bottom: 2rem;
  // To show where doc ends
  border-bottom: 2px dashed #ddd;
}
.__has-no-content {
  border: 2px dashed #ddd;
  border-radius: 0.75rem;
}

@media (max-width: 576px) {
  .Toolbar.btn-group {
    overflow-x: scroll;
    max-width: 100%;

  }
  
  .Toolbar.btn-group > .btn {
    font-size: 0.75rem;
  }
}

.__mobiledoc-editor {
  // This is just so we keep the editor stacked to the left
  outline: none;
}

.__mobiledoc-card {
  display: block;
  margin-top: -1.8rem;
  margin-bottom: -1.5em;
  border: 1px dashed #e5e5e5;
}

/* Typography */


.MobileDoc {
  h1 {
    margin: 1em auto 0.5em;
  }
  h2 {
    margin: 1em auto 0.5em;
  }
  h3 {
    margin: 1em auto 0.5em;
  }
  
  p {
    font-size: 1.1em;
    max-width: 40rem;
    margin: 0 auto 1em;
    line-height: 1.85em;
    text-align: left;
  }

  blockquote {
    font-size: 1.5em;
    max-width: 25rem;
    margin: 2em auto;
    text-align: left;
    padding-left: 0.5em;
    border-left: 15px solid var(--cyan);
  }
}
    
